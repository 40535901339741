.agent-count-container {
    position: relative;
    display: inline-block; /* Ensure it stays in the same line as the header */
    font-family: Avenir Next LT Pro, sans-serif;
}

.agent-count-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #319B42;
    color: white;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    cursor: pointer;
}

.agent-count-overlay {
    position: absolute;
    top: 40px; /* Adjust as needed */
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
}

.agent-count-overlay-content {
    display: flex;
    flex-direction: column;
}


/* AgentCountBadge.css */
.agent-modal-dialog {
    width: 100%;
    max-width: 100%; /* Ensures the modal doesn't exceed 95% */
    margin: auto;
}


.custom-overlay {
    position: fixed;
    top: 8vh; /* Adjust as needed */
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 0; /* Remove any padding */
}

.overlay-content {
    width: 100%; /* Ensure the overlay content spans the full width */
    background-color: white;
    padding: 20px;
    border-radius: 0; /* Remove rounding for full-width effect */
    max-height: 100%;
    overflow-y: auto;
    position: relative;
}


.agentcount-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Avenir Next LT Pro, sans-serif;
  }

  
  .agentcount-table th,
  .agentcount-table td {
    padding: 8px;
    white-space: nowrap; /* Prevent text from wrapping and causing columns to shrink */
    font-size: 10px;
    border: 1px solid #9f9f9f;
  }

  .agentcount-table td :first-child {
    text-align: left;
  }
  

  .agentcount-table th.sortable:hover {
    cursor: pointer;
  }
  
/* Row background colors */
.agentcount-table tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Light gray for even rows */
}

.agentcount-table tbody tr:nth-child(odd) {
    background-color: #ffffff; /* White for odd rows */
}

/* Sticky first column */
.agentcount-table td:first-child,
.agentcount-table th:first-child {
    position: sticky;
    left: 0;
    z-index: 1; /* Ensures it stays above other cells */
    background-color: inherit; /* Inherit the row's background color */
    border: inherit;
}

  
  .agentcount-table tbody tr {
    transition: background-color 0.3s;
  }
  
  .agentcount-table tbody tr:hover {
    background-color: #ebebeb;
  }
  
  .agentcount-table td {
    border-bottom: 1px solid #ddd;
    font-size: 12px;
  }
  
  .agentcount-table tbody tr:last-child td {
    border-bottom: none;
  }
  
  .highlight-today {
    font-weight: bold;
    color: orange !important;
  }
  .agentcount-table .editing-cell input {
    width: 100%; /* Ensures input matches cell width */
    height: 100%; /* Matches input height to cell height */
    box-sizing: border-box; /* Ensures padding is included in the total width/height */
    font-size: inherit; /* Matches the font size of the table cell */
    text-align: center; /* Ensures text alignment matches the table cell */
    border: none; /* Removes the default border for a seamless look */
    outline: none; /* Removes the focus outline for consistency */
    padding: 0; /* Matches the padding of the table cell */
    margin: 0; /* Removes any default margin */
    background-color: transparent; /* Ensures the input background is transparent */
  }