.hier-table-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  margin: auto;
  padding-left: 5px;
  padding-right: 10px;
  direction: ltr; /* Ensure left-to-right direction */
}

.hier-mga-header {
  margin-bottom: -5px;
  min-width: 150px;
}

.hierarchyTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Arial, sans-serif;
  box-shadow: none;
  width: 100%; /* Full width of the container */
	border-collapse: separate; /* Removes space between borders */
	margin-bottom: 20px;
	border-spacing: 2px;
}

.hierarchyTable th, .hierarchyTable td {
  border: 1px solid #7a7a7a;
  padding: 4px;
  text-align: left;
}


.hierarchyTable th {
  background-color: #00548C;
  color: white;
  font-weight: bold;
  font-size: 11px;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  position: sticky;
  top: 27px;
  z-index: 2;
  text-align: center;
	border: 1px solid transparent;
}

.hierarchyTable th, .hierarchyTable td {
  padding: 4px;
  width: 80px; /* Set a fixed width */
}

.hierarchyTable input {
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: center;
  box-sizing: border-box; /* Ensure the input width includes padding */
}

/* Ensure the width of the input fields matches the column widths */
.hierarchyTable td.editing-cell input {
  width: calc(100% - 8px); /* Adjust for padding */
}


.hierarchyTable .second-column {
  left: 50px; /* Adjust based on the width of the first column */
  z-index: 2; /* Ensure the second sticky column is on top of the first */
}

.hierarchyTable .day-header {
  position: sticky;

  top: 0; /* Adjust based on your requirements */
  z-index: 3; /* Ensure the day header is on top */
}

.hierarchyTable .first-row {
  top: 0; /* Adjust based on your requirements */
  z-index: 4; /* Ensure the first row is on top */
}

.hierarchyTable .second-row {
  top: 20px; /* Adjust based on the height of the first row */
  z-index: 5; /* Ensure the second row is on top of the first row */
}


.hierarchyTable td {
  border: 2px solid #d1d5db;
  padding: 3px;
  border-radius: 0.375rem;
  text-align: center;
  background-color: #fff;
  font-size: 12px;
}

.hierarchyTable input {
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: center;
}

.hier-change-week-left {
  display: flex;
  border: none;
  background-color: transparent;
  scale: 1.45;
  margin-right: 5px;
  color: #333;
}

.hier-change-week-right {
  display: flex;
  border: none;
  background-color: transparent;
  scale: 1.45;
  margin-left: 5px;
  color: #333;
}

/* Monday */
.hierarchyTable tr td:nth-child(9n+3), /* First day columns */
.hierarchyTable tr td:nth-child(9n+4),
.hierarchyTable tr td:nth-child(9n+5),
.hierarchyTable tr td:nth-child(9n+6),
.hierarchyTable tr td:nth-child(9n+7),
.hierarchyTable tr td:nth-child(9n+8),
.hierarchyTable tr td:nth-child(9n+9),
.hierarchyTable tr td:nth-child(9n+10),
.hierarchyTable tr td:nth-child(9n+11),
.hierarchyTable tr td:nth-child(9n+12){
  background-color: #ffffff; /* Light green */
}
/* tuesday */

.hierarchyTable tr td:nth-child(9n+13),
.hierarchyTable tr td:nth-child(9n+14),
.hierarchyTable tr td:nth-child(9n+15),
.hierarchyTable tr td:nth-child(9n+16),
.hierarchyTable tr td:nth-child(9n+17),
.hierarchyTable tr td:nth-child(9n+18),
.hierarchyTable tr td:nth-child(9n+19),
.hierarchyTable tr td:nth-child(9n+20),
.hierarchyTable tr td:nth-child(9n+21),
.hierarchyTable tr td:nth-child(9n+22){
  background-color: #bbbbbb; /* Light gray */
}
/* wednesday */

.hierarchyTable tr td:nth-child(9n+23),
.hierarchyTable tr td:nth-child(9n+24),
.hierarchyTable tr td:nth-child(9n+25),
.hierarchyTable tr td:nth-child(9n+26),
.hierarchyTable tr td:nth-child(9n+27),
.hierarchyTable tr td:nth-child(9n+28),
.hierarchyTable tr td:nth-child(9n+29),
.hierarchyTable tr td:nth-child(9n+30),
.hierarchyTable tr td:nth-child(9n+31),
.hierarchyTable tr td:nth-child(9n+32){
  background-color: #ffffff; /* Light gray */
}
/* thursday */


.hierarchyTable tr td:nth-child(9n+33),
.hierarchyTable tr td:nth-child(9n+34),
.hierarchyTable tr td:nth-child(9n+35),
.hierarchyTable tr td:nth-child(9n+36),
.hierarchyTable tr td:nth-child(9n+37),
.hierarchyTable tr td:nth-child(9n+38),
.hierarchyTable tr td:nth-child(9n+39),
.hierarchyTable tr td:nth-child(9n+40),
.hierarchyTable tr td:nth-child(9n+41),
.hierarchyTable tr td:nth-child(9n+42){
  background-color: #bbbbbb; /* Light gray */
}
/* friday */

.hierarchyTable tr td:nth-child(9n+43),
.hierarchyTable tr td:nth-child(9n+44),
.hierarchyTable tr td:nth-child(9n+45),
.hierarchyTable tr td:nth-child(9n+46),
.hierarchyTable tr td:nth-child(9n+47),
.hierarchyTable tr td:nth-child(9n+48),
.hierarchyTable tr td:nth-child(9n+49),
.hierarchyTable tr td:nth-child(9n+50),
.hierarchyTable tr td:nth-child(9n+51),
.hierarchyTable tr td:nth-child(9n+52){
  background-color: #ffffff; /* Light gray */
}
/* saturday */

.hierarchyTable tr td:nth-child(9n+53),
.hierarchyTable tr td:nth-child(9n+54),
.hierarchyTable tr td:nth-child(9n+55),
.hierarchyTable tr td:nth-child(9n+56),
.hierarchyTable tr td:nth-child(9n+57),
.hierarchyTable tr td:nth-child(9n+58),
.hierarchyTable tr td:nth-child(9n+59),
.hierarchyTable tr td:nth-child(9n+60),
.hierarchyTable tr td:nth-child(9n+61),
.hierarchyTable tr td:nth-child(9n+62){
  background-color: #bbbbbb; /* Light gray */
}
/* sunday */

.hierarchyTable tr td:nth-child(9n+63),
.hierarchyTable tr td:nth-child(9n+64),
.hierarchyTable tr td:nth-child(9n+65),
.hierarchyTable tr td:nth-child(9n+66),
.hierarchyTable tr td:nth-child(9n+67),
.hierarchyTable tr td:nth-child(9n+68),
.hierarchyTable tr td:nth-child(9n+69),
.hierarchyTable tr td:nth-child(9n+70),
.hierarchyTable tr td:nth-child(9n+71),
.hierarchyTable tr td:nth-child(9n+72){
  background-color: #ffffff; /* Light gray */
}

.hierarchyTable tr td:nth-child(9n+73),
.hierarchyTable tr td:nth-child(9n+74),
.hierarchyTable tr td:nth-child(9n+75),
.hierarchyTable tr td:nth-child(9n+76),
.hierarchyTable tr td:nth-child(9n+77),
.hierarchyTable tr td:nth-child(9n+78),
.hierarchyTable tr td:nth-child(9n+79),
.hierarchyTable tr td:nth-child(9n+80),
.hierarchyTable tr td:nth-child(9n+81),
.hierarchyTable tr td:nth-child(9n+82){
  background-color: #FFFF99; /* Light gray */
}

.hierarchyTable .totals-row td {
  background-color: #f0f0f0; /* Light gray background for contrast */
  font-weight: bold; /* Make the text bold */
  text-align: center; /* Center the text */
  color: #333; /* Darker font color */
}

.header-appts, .header-sits, .header-sales, .header-alp,
.header-refs, .header-refAppts, .header-refSits, .header-refSales, .header-refAlp {
  min-width: 65px; 
  text-align: center;
  background-color: #68B675;
}

.header-row-labeled {
  width: 100px; /* Ensure this matches the other header widths if needed */
}


/* Padding classes */
.hierarchyTable .padding20 {
}

.clname {
  font-weight: bold;
  background-color: #f1f3f4;
}



.hierarchyTable .mga-row {
  color: green;
  font-weight: bold;
}

.hierarchyTable .ga-row {
  color: blue;
  font-weight: bold;
}

.hierarchyTable .sa-row {
  color: pink;
  font-weight: bold;
}

.hierarchyTable .agt-row {
  color: black;
}

.hierarchyTable input:focus {
  outline: none;
}

.hierarchyTable td.editing-cell {
  background-color: #cbcbcb !important; /* Ensure it overrides other rules */
  width: calc(100% - 8px); 
}

.hierarchyTable .first-column {
  position: sticky;
  left: 0;
  background-color: #00548C;
  color: white;
  font-weight: bold;
  border-radius: 0;
  border: none;
  z-index: 1; /* Ensure the sticky columns are on top */
  white-space: nowrap;
}
.hierarchyTable button {
  background-color: white;
  border: none;
  cursor: pointer;
  margin-left: 2px;
  border-radius: 4px;
}
/* Adjust font size for smaller screens */
@media (max-width: 768px) {
  .hierarchyTable td, .hierarchyTable th {
    font-size: 12px;
  }
}


/* Customize the overall tabs container */
.nav-tabs {
  border-bottom: 2px solid #dee2e6;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

/* Customize individual tab items */
.nav-tabs .nav-item {
  margin-bottom: -1px;
}

/* Customize active tab */
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 500;
  font-size: 14px;
}

/* Customize inactive tabs */
.nav-tabs .nav-link {
  color: #6c757d;
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  font-size: 12px;
}

/* Customize hover state */
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  color: #495057;
  background-color: #f8f9fa;
}

/* Add some padding to the tabs */
.tab-content {
  padding: 5px;
  border-top: 0;
  background-color: transparent;
}

.date-range-navigation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0; /* Adds spacing around */
}

.date-range-navigation {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between buttons and dropdown */
}

.nav-button {
  background: transparent;
  color: grey;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  scale: 1.45;
  border-radius: 4px;
  transition: ease-in-out 0.2s;
}

.nav-button:hover {
  color: #333 !important;
  background-color: transparent;
}



/* Responsive adjustments */
@media (max-width: 768px) {
  .nav-tabs .nav-link {
    font-size: 12px;
  }

  .nav-tabs .nav-link.active {
    font-size: 13px;
  }
  .hierarchyTable .first-column {
    font-size: 10px;
  }
  .hier-mga-header {
    font-size: 12px;
    min-width: 100px;
    font-weight: bold;
  }
  .hierarchyTable td {
    font-size: 10px;
  }
  .hierarchyTable th {
    font-size: 10px;
  }
}
